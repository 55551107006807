import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Text,
  Button,
} from "@chakra-ui/react";
import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import MobileNav from "../components/MobileNav";
import axios from "axios";
import logo from "../images/vgd.png";

import message from "../images/message.svg";

import Swal from "sweetalert2";
// import emailjs, { init } from "emailjs-com";
import "../styles/contact.css";

// init("user_bcT1POGZr6wb1ww9bBJo6");

export default function privacy_policy({ location }) {
  const isBrowser = typeof window !== "undefined";

  return (
    <Box className="main" overflowX="hidden">
      {/* {width > 1280 ? (
        <Nav loc={location} page="/privacy_policy" />
      ) : (
        <MobileNav page="/privacy_policy" />
      )} */}
      <Box>
        <Flex
          w="100%"
          flexDirection="column"
          alignItems="start"
          flexWrap="wrap"
          px="5%"
          mt="60px"
          textAlign={"start"}
        >
          <Flex
            className="contactgetstarted"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            // gap={"100"}
            w="100%"
            py="10px"
            borderBottom="0.5px solid rgba(0, 0, 0, 0.15);"
          >
            <img
              src={logo}
              alt="visual_group_logo"
              style={{
                objectFit: "cover",
                height: 50,
                width: 218,
                cursor: "pointer",
                marginLeft: -25,
              }}
              onClick={() => {
                window.location.assign("/");
              }}
            />
            <Text
              fontWeight="bold"
              as="h3"
              fontSize={["23px", "23px", "32px", "32px", "32px"]}
            >
              Privacy Policy / Data Protection
            </Text>

            <Box></Box>
          </Flex>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            Introduction
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            Welcome to Ascend Group Media. We are committed to protecting your
            privacy and ensuring that your personal data is handled in a safe
            and responsible manner. This Privacy Policy explains how we collect,
            use, and safeguard your information.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            1. Who We Are
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            Ascend Group Media is a digital marketing and website development
            agency. We provide services that require accessing the backend of
            websites and managing leads and data. Our registered office is
            located at <br />
            [Sports City, Dubai, UAE].
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            2. Information We Collect
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            We may collect and process the following data:
            <br /> • Personal identification information (Name, email address,
            phone number, etc.) <br /> • Technical data (IP address, browser
            type, version, time zone setting, browser plug-in types, and
            versions, operating system, and platform) <br /> • Usage data
            (Information about how you use our website, products, and services)
            <br />• Marketing and communications data (Your preferences in
            receiving marketing from us and your communication preferences)
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            3. How We Use Your Information
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            We use the information we collect in the following ways: <br />• To
            provide and manage our services, including website development and
            marketing
            <br /> • To improve our website and services
            <br /> • To communicate with you, including sending you updates and
            marketing materials
            <br /> • To comply with legal obligations
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            4. Sharing Your Information
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            Under GDPR, we are required to disclose to our clients and users who
            we are sharing their information with. We may share your information
            with:
            <br /> • Service providers who perform services on our behalf (e.g.,
            hosting providers, email service providers)
            <br /> • Legal and regulatory authorities when required by law
            <br /> • Other third parties with your consent
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            4. Sharing Your Information
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            We implement a variety of security measures to maintain the safety
            of your personal information. These measures include secure servers,
            encrypted databases, and regular security audits.
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            6. Data Retention
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            We will retain your personal information only for as long as
            necessary to fulfill the purposes for which it was collected,
            including for the purposes of satisfying any legal, accounting, or
            reporting requirements.
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            7. Your Rights
          </Text>
          <Text
            mt={"5"}
            mb={"20"}
            fontSize={["15px", "15px", "18px", "18px", "18px"]}
          >
            Under GDPR, you have the following rights: <br /> • The right to
            access – You have the right to request copies of your personal data.{" "}
            <br /> • The right to rectification – You have the right to request
            that we correct any information you believe is inaccurate or
            complete information you believe If you have any other questions,
            please let us know.
          </Text>
        </Flex>

        {/* <Footer /> */}
      </Box>
    </Box>
  );
}
